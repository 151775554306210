@font-face {
font-family: 'TWKLausanne';
src: url(/_next/static/media/cd04ca8f27723f2e-s.p.woff) format('woff');
font-display: swap;
font-weight: 300;
}

@font-face {
font-family: 'TWKLausanne';
src: url(/_next/static/media/c4bc801764720ad8-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
}

@font-face {
font-family: 'TWKLausanne';
src: url(/_next/static/media/bc52409ef713fb39-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: 'TWKLausanne';
src: url(/_next/static/media/b329fbb4246123de-s.p.woff) format('woff');
font-display: swap;
font-weight: 600;
}

@font-face {
font-family: 'TWKLausanne';
src: url(/_next/static/media/4e598a2b8093b494-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
}@font-face {font-family: 'TWKLausanne Fallback';src: local("Arial");ascent-override: 89.26%;descent-override: 19.61%;line-gap-override: 0.00%;size-adjust: 102.08%
}.__className_0e3dac {font-family: 'TWKLausanne', 'TWKLausanne Fallback'
}

